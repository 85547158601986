<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Products dashboard</h1>
    <hr>
    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-secondary dropdown-toggle"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtnExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            @grid-ready="onGridReady"
            :rowData="rowData">
        </ag-grid-vue>
    </div>
    </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import { getProductsInfo } from '../helpers/endpoints';
import { seconds2TimeString } from '../../shared/helpers/dateTimeHelpers'
import TableButtonInfo from '../../shared/components/TableButtonInfo'
import { defaultButtonColumnDef } from '../../shared/helpers/GridHelpers'
import { useGrid } from '../../shared/composables/useGrid'


const timeSpendRenderer = (timeSpend) => {
    if (timeSpend.value)
        return seconds2TimeString(timeSpend.value)
    else
        return '-'
}

export default {
    components: { AgGridVue },
    setup() {
        const router = useRouter()
        const { 
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(() => {
            getProductsInfo().then((response) => {
                rowData.value = response;
                console.log( rowData.value );
            })
        })

        const columnDefs = reactive([
                { headerName: "Reference", field: "reference", filter: 'agTextColumnFilter' },
                { headerName: "Total items", field: "total_manufatured", filter: 'agNumberColumnFilter' },
                { headerName: "Mean products / work order", field: 'mean_order_products', filter: 'agNumberColumnFilter' },
                { headerName: "Total work orders", field: 'n_orders', filter: 'agNumberColumnFilter' },
                {
                    headerName: 'Registry time (HH:mm:ss)',
                    field: 'time',
                    cellRenderer: timeSpendRenderer
                },
                {
                    headerName: 'time/item (HH:mm:ss)',
                    field: 'time',
                    valueGetter: (params) => {
                        console.log(params);
                        return seconds2TimeString(params.data['time'] / params.data['total_manufatured'])
                     },
                },
                {
                    field: 'id',
                    cellRenderer: 'btnInfoRenderer',
                    cellRendererParams: {
                        action: (data) => {
                            console.log(data.id);
                            router.push({
                                name: 'backoffice-dashboard-products-details',
                                params: {
                                    id: data.id
                                },
                            })
                        }
                    },
                    ...defaultButtonColumnDef
                }
            ])
        
        const frameworkComponents = reactive({
            btnInfoRenderer: TableButtonInfo
        })

        return {
            columnDefs,
            defaultColDef,
            frameworkComponents,
            rowData,

            onGridReady,
            onBtnExport: () => exportAsCSV()
        }
    }
}
</script>

<style>

</style>